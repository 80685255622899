/* eslint-disable react/no-danger */
import { graphql } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import SubHeaderGeneric from "../components/SubHeaderGeneric";
import FeatureGeneric from "../components/FeatureGeneric";
import ControlProtocolList from "../components/ControlProtocolList";
import AttributeList from "../components/AttributeList";
import FinishNameList from "../components/FinishNameList";
import DistributionList from "../components/DistributionList";
import { arrayEquals, forceTwoDecimal } from "../utils/helpers";
import Configurator from "../components/Configurator";
import ProductComponents from "../components/ProductComponents";
import DownloadList from "../components/DownloadList";
import ProductDimensionsImage from "../components/ProductDimensionsImage";
import SeoImageSrc from "../components/SeoImageSrc";
import ProjectImageGallery from "../components/ProjectImageGallery";

export default function T2Default({ data, location }) {
  // clg({ pageContext });
  // clg(data);
  // clg("T2 record id", data.T2.recordId);
  const T2 = data.T2.data;
  const T1s = data.T1s.nodes;
  // const allProductImages = data.allProductImages.nodes;

  const { productPath } = data.site.siteMetadata;
  // const allDimensionsDiagrams = data.allDimensionsDiagrams.nodes;

  /* Clone Components */
  if (T2.Components) {
    T2.webComponents = T2.Components;
  }

  /* Prepare Downloads v2 */

  const allInstallationManuals = data.allInstallationManuals.nodes;
  const allProductPhotometry = data.allProductPhotometry.nodes;
  const allBrochures = data.allBrochures.nodes;
  T2.downloadsList = {};
  T2.downloadsList.installationManual = null;
  T2.downloadsList.photometry = null;
  T2.downloadsList.brochure = null;

  if (T2.wDIM) {
    T2.downloadsList.installationManual = {
      label: "Installation Manual",
      filename: `${T2.wDIM}`,
      aside: "PDF",
    };
  }

  if (T2.webDP) {
    T2.downloadsList.photometry = {
      label: "Photometry",
      filename: `${T2.webDP}`,
      aside: "IES",
    };
  }

  if (T2.wB) {
    T2.downloadsList.brochure = {
      label: "Brochure",
      filename: `${T2.wB}`,
      aside: "PDF",
    };
  }

  // clg(T2.downloadsList.brochure);

  /* Prepare Downloads */
  // T2.downloadsParsed = [];

  // if (T2.webDP)
  //   T2.downloadsParsed.push({
  //     label: "Photometry",
  //     filename: `${T2.webDP}`,
  //     aside: "IES",
  //   });

  /* Product Name */
  let tempProductName = T2.webDisplayNameFirst;
  const productTitle = [{ name: T2.webDisplayNameFirst }];
  if (T2.webDisplayNameSecond) {
    tempProductName += ` ${T2.webDisplayNameSecond}`;
    productTitle.push({ name: T2.webDisplayNameSecond });
  }

  /* Hero Image */
  let heroImageFilename = "";
  if (
    T2.webHeroImage &&
    T2.webHeroImage.length > 0 &&
    T2.webHeroImage[0].data.Filename
  ) {
    heroImageFilename = T2.webHeroImage[0].data.Filename;
  }
  const seoImageSrc = SeoImageSrc(heroImageFilename);

  /* TODO Tidy mailto and categories for T2 */
  const tempMailtoString = `mailto:general@ratio.lighting?subject=Enquiry: ${tempProductName}&body=${tempProductName}`;
  const tempSubheaderLinks = [{ name: "Enquire", path: tempMailtoString }];

  /* Project Image */

  // clg(data.allT2sWithProjectImages.nodes);
  // clg(T2.webFullName);

  const allT2sWithProjectImages = data.allT2sWithProjectImages.nodes;
  const ProjectImages =
    allT2sWithProjectImages.length > 0 && T2.webFullName
      ? allT2sWithProjectImages.find(
          (node) => node.data.Full_Name === T2.webFullName
        )?.data.Project_Images || []
      : [];

  if (ProjectImages.length > 0) {
    ProjectImages.forEach((image) => {
      // clg(image.data.File_Name);
    });
  }

  return (
    <>
      <Helmet>
        <title>{tempProductName} — Ratio Lighting</title>
        <meta property="og:image" content={seoImageSrc && seoImageSrc} />
        <meta property="og:title" content={tempProductName} key="ogtitle" />
        {T2.webOverviewCopy && T2.webOverviewCopy.childMarkdownRemark && (
          <meta
            name="description"
            content={T2.webOverviewCopy.childMarkdownRemark.excerpt}
          />
        )}
        {T2.webOverviewCopy && T2.webOverviewCopy.childMarkdownRemark && (
          <meta
            property="og:description"
            content={T2.webOverviewCopy.childMarkdownRemark.excerpt}
            key="ogdesc"
          />
        )}
      </Helmet>

      <main>
        <SubHeaderGeneric
          productName={productTitle}
          links={tempSubheaderLinks}
          configureButton={T2.webShowConfigurator}
          pathname={location.pathname && location.pathname}
        />
        <div id="grid-t2-a" className="px-medium mb-xxl mb-mm-medium">
          <div id="feature-image">
            {heroImageFilename && (
              <FeatureGeneric
                filename={heroImageFilename}
                alt="tempProductName"
                // productImages={allProductImages}
              />
            )}
            <hr className="hide-mobile-only hide-mxs-only hide-mxl mt-large mt-ml-small mb-medium mb-ml-small" />
          </div>

          <hr className="hide-mm mt-large mt-ml-small mb-medium mb-ml-small" />

          <div id="overview" className="">
            <article className="fs-small fs-ml-medium fs-mxl-small fs-mxxl-large fs-mxxxl-xl">
              {T2.webOverviewCopy && T2.webOverviewCopy.childMarkdownRemark && (
                <>
                  <section className="mb-medium">
                    <h2 className="fs-ms-medium-only fw-medium c-em mb-xs">
                      Overview
                    </h2>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: T2.webOverviewCopy.childMarkdownRemark.html,
                      }}
                    />
                  </section>
                  <hr className="hide-mm mb-medium" />
                </>
              )}

              {T2.webFunctionCopy && T2.webFunctionCopy.childMarkdownRemark && (
                <>
                  <section className="mb-medium">
                    <h3 className="fs-ms-medium-only fw-medium c-em mb-xs">
                      Function
                    </h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: T2.webFunctionCopy.childMarkdownRemark.html,
                      }}
                    />
                  </section>
                  <hr className="hide-mm mb-medium" />
                </>
              )}

              {T2.webDetailCopy && T2.webDetailCopy.childMarkdownRemark && (
                <>
                  <section className="mb-medium">
                    <h3 className="fs-ms-medium-only fw-medium c-em mb-xs">
                      Detail
                    </h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: T2.webDetailCopy.childMarkdownRemark.html,
                      }}
                    />
                  </section>
                  <hr className="hide-mm mb-medium" />
                </>
              )}

              {T2.webFinish && T2.webFinish.length > 0 && (
                <>
                  <section className="mb-medium">
                    <h3 className="fs-ms-medium-only fw-medium c-em mb-xs">
                      Finishes
                    </h3>
                    <ul>
                      <FinishNameList finishes={T2.webFinish} />
                    </ul>
                  </section>
                  <hr className="hide-mm mb-medium" />
                </>
              )}

              {T2.downloadsList.photometry ||
              T2.downloadsList.installationManual ||
              T2.downloadsList.brochure ? (
                <>
                  <section className="mb-medium">
                    <h3 className="fs-ms-medium-only fw-medium c-em mb-xs">
                      Downloads
                    </h3>
                    <ul>
                      <DownloadList
                        downloadsList={T2.downloadsList}
                        allProductPhotometry={allProductPhotometry}
                        allInstallationManuals={allInstallationManuals}
                        allBrochures={allBrochures}
                      />
                    </ul>
                  </section>
                  <hr className="hide-mm mb-medium" />
                </>
              ) : null}

              {/* {T2.downloadsParsed && T2.downloadsParsed.length > 0 && (
                <>
                  <section className="mb-medium">
                    <h3 className="fs-ms-medium-only fw-medium c-em mb-xs">
                      Downloads
                    </h3>
                    <ul>
                      <DownloadList
                        productDownloads={T2.downloadsParsed}
                        allProductPhotometry={allProductPhotometry}
                      />
                    </ul>
                  </section>
                  <hr className="hide-mm mb-medium" />
                </>
              )} */}
            </article>
          </div>
          <div
            id="attributes"
            className="fs-small fs-ml-medium fs-mxl-small fs-mxxl-large fs-mxxxl-xl"
          >
            {(T2.webPower && T2.webPower.length > 0) ||
            T2.webFluxRange ||
            (T2.webColourTemperature && T2.webColourTemperature.length > 0) ||
            (T2.webCRI && T2.webCRI.length > 0) ? (
              <>
                <dl className="flex-2-grid">
                  {T2.webPower && T2.webPower.length > 0 && (
                    <>
                      <dt className="column">System Power</dt>
                      <dd className="column">
                        <ul>
                          <AttributeList
                            values={T2.webPower}
                            prefix="Power"
                            unitBack="W"
                          />
                          {/* {T2.webPower.sort().map((power) => (
                      <li prefix={`Power-${power}`}>{power}W</li>
                    ))} */}
                        </ul>
                      </dd>
                    </>
                  )}
                  {((Array.isArray(T2.pbVAC) &&
                    T2.pbVAC.some((value) => value != null)) ||
                    (Array.isArray(T2.pbVDC) &&
                      T2.pbVDC.some((value) => value != null))) && (
                    <>
                      <dt className="column">Voltage</dt>
                      <dd className="column">
                        <ul>
                          {Array.isArray(T2.pbVDC) &&
                            T2.pbVDC.some((value) => value != null) && (
                              <AttributeList
                                values={T2.pbVDC}
                                unitFront="DC "
                                unitBack="V"
                              />
                            )}
                          {Array.isArray(T2.pbVAC) &&
                            T2.pbVAC.some((value) => value != null) && (
                              <AttributeList
                                values={T2.pbVAC}
                                unitFront="AC "
                                unitBack="V"
                              />
                            )}
                        </ul>
                      </dd>
                    </>
                  )}
                  {T2.webFluxRange && (
                    <>
                      <dt className="column">Luminous Flux</dt>
                      <dd className="column">
                        {T2.webFluxRange}lm
                        <br />
                        {T2.webFluxRange.includes("-") && (
                          <>
                            <aside className="fs-tiny c-fade">
                              Based on configuration
                            </aside>
                          </>
                        )}
                      </dd>
                    </>
                  )}
                  {T2.webColourTemperature &&
                    T2.webColourTemperature.length > 0 && (
                      <>
                        <dt className="column">Colour Temperature</dt>
                        <dd className="column">
                          <ul>
                            <AttributeList
                              values={T2.webColourTemperature}
                              prefix="CT"
                              unitBack="K"
                            />
                          </ul>
                        </dd>
                      </>
                    )}
                  {T2.webCRI && T2.webCRI.length > 0 && (
                    <>
                      <dt className="column">CRI</dt>
                      <dd className="column">
                        <ul>
                          <AttributeList
                            values={T2.webCRI}
                            prefix="CRI"
                            unitFront="Ra"
                          />
                        </ul>
                      </dd>
                    </>
                  )}
                  {T2.wTM30RF && T2.wTM30RF.length > 0 && (
                    <>
                      <dt className="column">TM-30 RF</dt>
                      <dd className="column">
                        <ul>
                          <AttributeList
                            values={T2.wTM30RF}
                            prefix=""
                            unitFront=""
                          />
                        </ul>
                      </dd>
                    </>
                  )}
                  {T2.wTM30RF && T2.wTM30RF.length > 0 && (
                    <>
                      <dt className="column">TM-30 RG</dt>
                      <dd className="column">
                        <ul>
                          <AttributeList
                            values={T2.wTM30RG}
                            prefix=""
                            unitFront=""
                          />
                        </ul>
                      </dd>
                    </>
                  )}
                  {T2.wLM && T2.wLM.length > 0 && (
                    <>
                      <dt className="column">Lumen Maintenance</dt>
                      <dd className="column">
                        <ul>
                          <li>{T2.wLM}</li>
                        </ul>
                      </dd>
                    </>
                  )}
                </dl>
                <hr className="my-medium my-ml-small" />
              </>
            ) : null}
            {T2.webOpticalAssemblyCopy ||
            T2.webDistributionType ||
            (T2.webDistribution && T2.webDistribution.length > 0) ? (
              <>
                <dl className="flex-2-grid">
                  {T2.webOpticalAssemblyCopy || T2.webDistributionType ? (
                    <>
                      <dt className="column">Optical Assembly</dt>
                      <dd className="column">
                        <ul>
                          {T2.webOpticalAssemblyCopy && (
                            <li>{T2.webOpticalAssemblyCopy}</li>
                          )}
                          {T2.webDistributionType && (
                            <li>{T2.webDistributionType}</li>
                          )}
                        </ul>
                      </dd>
                    </>
                  ) : null}
                  {T2.webDistribution && T2.webDistribution.length > 0 && (
                    <>
                      <dt className="column">Distribution</dt>
                      <dd className="column">
                        <ul>
                          {/* <AttributeList
                      values={T2.webDistribution}
                      prefix="Distribution"
                    /> */}
                          <DistributionList
                            distributions={T2.webDistribution}
                          />
                        </ul>
                      </dd>
                    </>
                  )}
                </dl>
              </>
            ) : null}

            {(T2.wTilt || T2.wRotation) && (
              <>
                <hr className="my-medium my-ml-small" />
                <dl className="flex-2-grid">
                  {T2.wTilt && (
                    <>
                      <dt className="column">Tilt</dt>
                      <dd className="column">{T2.wTilt}°</dd>
                    </>
                  )}
                  {T2.wRotation && (
                    <>
                      <dt className="column">Rotation</dt>
                      <dd className="column">{T2.wRotation}°</dd>
                    </>
                  )}
                </dl>
              </>
            )}

            {/* 
              Ugly hack below to avoid extra horizontal rules 
              I think it only shows a ruler above IP if any of
              the stuff above is shown
            */}
            {T2.webIPRating && (
              <>
                {(T2.webPower && T2.webPower.length > 0) ||
                T2.webFluxRange ||
                (T2.webColourTemperature &&
                  T2.webColourTemperature.length > 0) ||
                (T2.webCRI && T2.webCRI.length > 0) ||
                T2.webOpticalAssemblyCopy ||
                T2.webDistributionType ||
                (T2.webDistribution && T2.webDistribution.length > 0) ? (
                  <hr className="my-medium my-ml-small" />
                ) : null}
                <dl className="flex-2-grid">
                  {/* <dt className="column">IP Rating</dt>
                  <dd className="column">IP{T2.webIPRating}</dd> */}
                  {T2.webIPRating && (
                    <>
                      <dt className="column">IP Rating</dt>
                      <dd className="column">IP{T2.webIPRating}</dd>
                    </>
                  )}
                  {T2.webIKRating && (
                    <>
                      <dt className="column">IK Rating</dt>
                      <dd className="column">IK{T2.webIKRating}</dd>
                    </>
                  )}
                </dl>
              </>
            )}
            {/* Control Protocol */}
            {(T2.webControlProtocolRemote &&
              T2.webControlProtocolRemote.length > 0) ||
            (T2.webControlProtocolIntegrated &&
              T2.webControlProtocolIntegrated.length > 0) ? (
              <>
                <hr className="my-medium my-ml-small" />
                <dl className="flex-2-grid">
                  <dt className="column">Control</dt>
                  <dd className="column">
                    <dl>
                      {arrayEquals(
                        T2.webControlProtocolRemote,
                        T2.webControlProtocolIntegrated
                      ) ? (
                        <>
                          <dt className="fs-tiny fw-medium">
                            Integrated or Remote
                          </dt>
                          <dd className="pb-xs">
                            {T2.webControlProtocolIntegrated &&
                              T2.webControlProtocolIntegrated.length > 0 && (
                                <>
                                  <ul>
                                    <ControlProtocolList
                                      protocols={
                                        T2.webControlProtocolIntegrated
                                      }
                                    />
                                  </ul>
                                </>
                              )}
                          </dd>
                        </>
                      ) : (
                        <>
                          {T2.webControlProtocolIntegrated &&
                            T2.webControlProtocolIntegrated.length > 0 && (
                              <>
                                <dt className="fs-tiny fw-medium">
                                  Integrated
                                </dt>
                                <dd className="pb-xs">
                                  <ul>
                                    <ul>
                                      <ControlProtocolList
                                        protocols={
                                          T2.webControlProtocolIntegrated
                                        }
                                      />
                                    </ul>
                                  </ul>
                                </dd>
                              </>
                            )}
                          {T2.webControlProtocolRemote &&
                            T2.webControlProtocolRemote.length > 0 && (
                              <>
                                <dt className="fs-tiny fw-medium">Remote</dt>
                                <dd className="pb-xs">
                                  <ul>
                                    <ul>
                                      <ControlProtocolList
                                        protocols={T2.webControlProtocolRemote}
                                      />
                                    </ul>
                                  </ul>
                                </dd>
                              </>
                            )}
                        </>
                      )}
                    </dl>
                    <aside className="fs-tiny c-fade">Driver Included</aside>
                  </dd>
                </dl>
              </>
            ) : null}

            {/* Carbon */}
            {T2.webCarbonOffset &&
              T2.webCarbonEmbodiedValue &&
              T2.webCarbonStatus && (
                <>
                  <hr className="my-medium my-ml-small" />
                  <dl className="flex-2-grid">
                    {T2.webCarbonEmbodiedValue && (
                      <>
                        <dt className="column">Embodied Carbon</dt>
                        <dd className="column">
                          {forceTwoDecimal(T2.webCarbonEmbodiedValue)}
                          kgCO&sup2;e
                        </dd>
                      </>
                    )}
                    {T2.webCarbonOffset && (
                      <>
                        <dt className="column">Carbon Offset</dt>
                        <dd className="column">
                          {forceTwoDecimal(T2.webCarbonOffset)}kgCO&sup2;e
                        </dd>
                      </>
                    )}
                    {T2.webCarbonStatus && (
                      <>
                        <dt className="column">
                          {/* <a
                            href="https://ratio.lighting/brochures/Ratio-Carbon-Sequestion-v02.pdf"
                            target="_blank"
                            className="carbon-program-link"
                            rel="noreferrer"
                          >
                            Carbon {T2.webCarbonStatus}
                          </a> */}
                          Carbon {T2.webCarbonStatus}
                        </dt>
                        <dd className="column">
                          {T2.webCarbonEmbodiedValue && T2.webCarbonOffset && (
                            <>
                              {" "}
                              {forceTwoDecimal(
                                T2.webCarbonOffset - T2.webCarbonEmbodiedValue
                              )}
                              kgCO&sup2;e
                            </>
                          )}
                        </dd>
                      </>
                    )}
                    <dt className="column">&nbsp;</dt>
                    <dd className="column">
                      <aside className="fs-tiny c-fade">
                        <a
                          href="https://ratio.lighting/brochures/Ratio-Carbon-Sequestion-v02.pdf"
                          target="_blank"
                          className="carbon-program-link"
                          rel="noreferrer"
                        >
                          Carbon Sequestion Program
                        </a>
                      </aside>
                    </dd>
                  </dl>
                </>
              )}
          </div>
          <hr className="mt-large hide-mm" />
        </div>

        {T2.webDimensionsDiagrams ? (
          <div className="grid-fixed-1 px-medium mb-xxl mb-ml-medium">
            <section className="mb-medium">
              <ProductDimensionsImage
                diagramsString={T2.webDimensionsDiagrams}
              />
            </section>
            <hr className="hide-mm mb-medium" />
          </div>
        ) : null}

        {ProjectImages.length > 0 ? (
          <div className="grid-fixed-1 px-medium mb-xxl mb-ml-medium">
            <section className="mb-medium">
              <ProjectImageGallery
                projectImages={ProjectImages}
                T2Fullname={T2.webFullName}
              />
            </section>
            <hr className="hide-mm mb-medium" />
          </div>
        ) : null}

        {T2.webShowConfigurator && (
          <div className="grid-fixed-1 px-medium mb-xxl mb-ml-medium">
            <hr className="mb-medium mb-xxl mb-ml-large hide-mobile-only" />
            <div className="">
              <section
                id="configurator"
                className="configurator-container mb-medium fs-small fs-ml-medium fs-mxl-small fs-mxxl-large fs-mxxxl-xl"
              >
                <Configurator T1s={T1s} productPath={productPath} />
              </section>
            </div>
          </div>
        )}

        {T2.webComponents && T2.webComponents.length > 0 ? (
          <div className="grid-fixed-1 px-medium mb-xxl mb-ml-medium">
            <section className="mb-medium">
              {/* <h3 className="fs-ms-medium-only fw-medium c-em mb-xs">
                Components
              </h3> */}
              <ProductComponents components={T2.webComponents} />
            </section>
            <hr className="hide-mm mb-medium" />
          </div>
        ) : null}
      </main>
    </>
  );
}

export const query = graphql`
  query ($T2RecordId: String!, $webDP: String, $wDIM: String, $wB: String) {
    T2: airtableProductsT2(recordId: { eq: $T2RecordId }) {
      recordId
      data {
        pbVAC
        pbVDC
        wB
        wDIM
        webCarbonOffset
        webCarbonEmbodiedValue
        webCarbonStatus
        webColourTemperature
        webControlProtocolIntegrated
        webControlProtocolRemote
        webCRI
        webDetailCopy {
          childMarkdownRemark {
            html
          }
        }
        webDimensionsDiagrams
        webDisplayNameFirst
        webDisplayNameSecond
        webDistribution
        webDistributionType
        wTilt
        wRotation
        webFinish
        webFlux
        webFluxRange
        webFullName
        webFunctionCopy {
          childMarkdownRemark {
            html
          }
        }
        webHeroImage {
          data {
            Filename
          }
        }
        webIPRating
        webIKRating
        wLM
        webOpticalAssemblyCopy
        webOverviewCopy {
          childMarkdownRemark {
            excerpt(pruneLength: 140)
            html
          }
        }

        webDP
        webPower
        webT4Slug
        webSlug
        wTM30RF
        wTM30RG
        webShowConfigurator
        Components {
          recordId
          data {
            webT3FullName
            webComponentSortOrder
            webControlProtocol
            webControlProtocolIntegrated
            webControlProtocolRemote
            webProductType
            webPower
            webModelCode
            webModelName
            webIPRating
            webColourTemperature
            webCRI
            webFlux
            webVoltage
          }
        }
      }
    }
    T1s: allAirtableProductsT1(
      filter: { data: { T2: { eq: $T2RecordId }, webPublished: { eq: true } } }
    ) {
      nodes {
        recordId
        data {
          webCRI
          webColourTemperature
          webControlProtocol
          webControlProtocolIntegrated
          webControlProtocolRemote
          webDistribution
          webFinish
          webFlux
          webIPRating
          webModelCode
          wMCN
          # webOpticalAccessory
          webPower
          webT4Slug
          webSlug
        }
      }
    }
    allProductPhotometry: allFile(
      filter: { sourceInstanceName: { eq: "photometry" }, name: { eq: $webDP } }
    ) {
      nodes {
        publicURL
        sourceInstanceName
        size
        relativeDirectory
        ext
        name
      }
    }
    allInstallationManuals: allFile(
      filter: {
        sourceInstanceName: { eq: "installation_manuals" }
        name: { eq: $wDIM }
      }
    ) {
      nodes {
        publicURL
        sourceInstanceName
        size
        relativeDirectory
        ext
        name
      }
    }
    allBrochures: allFile(
      filter: { sourceInstanceName: { eq: "brochures" }, name: { eq: $wB } }
    ) {
      nodes {
        publicURL
        sourceInstanceName
        size
        relativeDirectory
        ext
        name
      }
    }
    site: site {
      siteMetadata {
        productPath
        siteUrl
      }
    }
    allT2sWithProjectImages: allAirtableAllWebProjectImageT2S {
      nodes {
        data {
          Full_Name
          Project_Images {
            data {
              File_Name
            }
          }
        }
      }
    }
  }
`;
