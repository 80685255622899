import React from "react";
import getAttributeValues from "../utils/getAttributeValues";

export default function ProductComponentsTable({ type, components }) {
  /* Sort components by sort order */
  const componentsSorted = components.sort(function (a, b) {
    if (a.data.webComponentSortOrder === b.data.webComponentSortOrder) {
      if (a.data.webModelCode > b.data.webModelCode) return 1;
      if (a.data.webModelCode < b.data.webModelCode) return -1;
    }

    if (a.data.webComponentSortOrder === null) return 1;

    if (a.data.webComponentSortOrder > b.data.webComponentSortOrder) return 1;
    if (a.data.webComponentSortOrder < b.data.webComponentSortOrder) return -1;

    return 0;
  });

  /* Potential attributes we will display */

  const attributesAvailable = {};

  attributesAvailable.Model = { label: type, key: "webModelCode" };
  // attributesAvailable.Model = { label: "Model", key: "webModelCode" };
  attributesAvailable.Name = { label: "Name", key: "webModelName" };
  attributesAvailable["T3 Name"] = { label: "Name", key: "webT3FullName" };
  attributesAvailable.Voltage = {
    label: "Voltage",
    key: "webVoltage",
    suffix: "V",
  };
  attributesAvailable.Power = {
    label: "Power",
    key: "webPower",
    suffix: "W",
  };
  attributesAvailable["Colour Temperature"] = {
    label: "Colour",
    key: "webColourTemperature",
    suffix: "K",
  };
  attributesAvailable.CRI = { label: "CRI", key: "webCRI", prefix: "Ra" };
  attributesAvailable.Output = {
    label: "Flux",
    key: "webFlux",
    suffix: "lm",
  };
  attributesAvailable["Control Protocol Integrated"] = {
    label: "Integrated",
    key: "webControlProtocolIntegrated",
  };
  attributesAvailable["Control Protocol Remote"] = {
    label: "Remote",
    key: "webControlProtocolRemote",
  };
  attributesAvailable["IP Rating"] = {
    label: "IP Rating",
    key: "webIPRating",
    prefix: "IP",
  };

  /* Fetch all values for each attribute */

  for (const [key, attribute] of Object.entries(attributesAvailable)) {
    const values = getAttributeValues({
      key: attribute.key,
      products: componentsSorted,
      prefix: attribute.prefix,
      suffix: attribute.suffix,
    });
    attribute.value = values;
  }

  /* What attributes do we need for the table? */

  const attributesNeeded = [];

  switch (type) {
    case "Light Source":
      attributesNeeded.push(
        "Model",
        "T3 Name",
        "Power",
        "Colour Temperature",
        "CRI",
        "Output",
        "IP Rating"
      );
      break;
    case "Control Device":
      attributesNeeded.push(
        "Model",
        "T3 Name",
        "Voltage",
        "Power",
        "Control Protocol Integrated",
        "Control Protocol Remote",
        "IP Rating"
      );
      break;
    default:
      attributesNeeded.push("Model", "Name");
  }

  /* Populate the table header */

  const tableHeaders = [];
  const tableColumnValues = [];
  attributesNeeded.forEach(function (attribute, i) {
    if (
      attributesAvailable[attribute] &&
      attributesAvailable[attribute].value &&
      attributesAvailable[attribute].value.length > 0
    ) {
      tableHeaders.push(attributesAvailable[attribute].label);
      tableColumnValues.push(attributesAvailable[attribute].value);
    }
  });

  /* Populate the table Rows */

  const tableRows = [];
  if (tableColumnValues && tableColumnValues.length > 0) {
    const rowCount = tableColumnValues[0].length;
    for (let i = 0; i < rowCount; i += 1) {
      tableRows.push(tableColumnValues.map((column) => column[i]));
    }
  }

  return (
    <div className="table-overflow-wrapper">
      <table className="generic">
        {/* <caption className="c-em">{type}</caption> */}
        <thead>
          <tr>
            {tableHeaders.map((header, i) => (
              <th key={`component-${type}-${i}`} className="fw-medium">
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableRows.map((row, x) => (
            <tr key={`component-${type}-row-${x}`}>
              {row.map((col, y) => (
                <td key={`component-${type}-${x}-${y}`} className="column">
                  {col}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
