import React from "react";
import ProductComponentsTable from "./ProductComponentsTable";

export default function ProductComponents({ components }) {
  if (!components || components.length < 1) return <></>;

  // TODO Hardcoded component types based on Product Type. Can populate these and dictate order via Product Types table
  const componentsTyped = [
    { name: "Profile", label: "Profile", components: null },
    { name: "Part", label: "Parts", components: null },
    { name: "Light Source", label: "Light Sources", components: null },
    { name: "Control Device", label: "Control Devices", components: null },
    { name: "Accessory", label: "Accessories", components: null },
  ];

  componentsTyped.forEach((type) => {
    const items = components.filter(
      (item) => item.data.webProductType === type.name
    );
    if (items.length > 0) type.components = items;
  });

  return (
    <>
      {componentsTyped.map((type, i) => {
        if (type.components && type.components.length > 0) {
          return (
            <div
              className="component-wrapper mb-large"
              key={`component-table-${i}`}
            >
              <ProductComponentsTable
                type={type.name}
                components={type.components}
              />
            </div>
          );
        }
        return <React.Fragment key={`component-table-empty-${i}`} />;
      })}
    </>
  );
}
