export default function getAttributeValues({ key, products, prefix, suffix }) {
  const values = [];

  // if (products && products.length > 0) {
  //   products.forEach((product) => {
  //     if (product.data[key] === null) {
  //       values.push(product.data[key]);
  //     } else {
  //       const valueWithUnit = `${prefix || ""}${product.data[key]}${
  //         suffix || ""
  //       }`;
  //       values.push(valueWithUnit);
  //     }
  //   });
  // }

  if (products && products.length > 0) {
    products.forEach((product) => {
      if (product.data[key] === null) {
        values.push(product.data[key]);
      } else {
        const value = product.data[key];
        const isRGBType = ["RGB", "RGBW", "RGBTW", "RGBK", "RGBWK"].includes(
          value
        );

        const valueWithUnit = `${prefix || ""}${value}${
          isRGBType ? "" : suffix || ""
        }`;
        values.push(valueWithUnit);
      }
    });
  }

  /* Are all values null? */
  if (values.every((e) => e === null)) return [];

  if (values.length > 0) return values;

  return [];
}
