// Probably not used anymore
// export const getProductImageData = (filename, productImages) => {
//   const imageData = productImages.find((x) => x.relativePath === filename);
//   // if (imageData) {
//   //   const image = getImage(imageData);
//   //   if (image) return image;
//   // }
//   if (imageData) return imageData;
//   return false;
// };

export const forceTwoDecimal = (num) => parseFloat(num).toFixed(2);

export const onlyUnique = (value, index, self) => self.indexOf(value) === index;
export const numericalSort = (a, b) => a - b;

export const getMinMax = (values) => {
  alert(values);
  return false;
};

export const arrayEquals = (a, b) =>
  Array.isArray(a) &&
  Array.isArray(b) &&
  a.length === b.length &&
  a.every((val, index) => val === b[index]);

export const naturalSort = (array, key) => {
  array.sort((a, b) =>
    a.data[key].localeCompare(b.data[key], "en", {
      numeric: true,
      ignorePunctuation: true,
    })
  );
  return array;
};

export const naturalSortNameSplit = (array) => {
  array.sort((a, b) => {
    if (a.data.webDisplayNameFirst === b.data.webDisplayNameFirst) {
      return a.data.webDisplayNameSecondShort.localeCompare(
        b.data.webDisplayNameSecondShort,
        "en",
        {
          numeric: true,
          ignorePunctuation: true,
        }
      );
    }
    return a.data.webDisplayNameFirst.localeCompare(
      b.data.webDisplayNameFirst,
      "en",
      {
        numeric: true,
        ignorePunctuation: true,
      }
    );
  });
  return array;
};

export const sortFinishes = (finishes) => {
  const order = [
    "White",
    "Black",
    "White with Black Interior",
    "Black with White Interior",
    "Natural Anodised",
  ];
  const sorted = [];
  order.forEach((color) => {
    if (finishes.includes(color)) {
      sorted.push(color);
    }
  });
  return sorted;
};

export const sortControlProtocols = (protocols) => {
  const order = [
    "Fixed Output",
    "Phase DIM",
    "DALI",
    "Push DIM",
    "Casambi Bluetooth",
    "0-10V",
  ];
  const sorted = [];
  order.forEach((protocol) => {
    if (protocols.includes(protocol)) {
      sorted.push(protocol);
    }
  });
  return sorted;
};
