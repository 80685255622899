import React from "react";
import { sortFinishes } from "../utils/helpers";

export default function FinishNameList({ finishes }) {
  const sorted = sortFinishes(finishes);

  return (
    <>
      {sorted.map((FN) => (
        <li key={`FN-${FN}`}>{FN}</li>
      ))}
    </>
  );
}
