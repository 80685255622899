/* eslint-disable no-nested-ternary */
import getDistributionNumerical from "./getDistributionNumerical";
import { sortControlProtocols } from "./helpers";

export default function formatConfiguratorAttribute(name, values) {
  if (!name || !values || values.length < 1) return;
  // Ignore webSlug and webFlux
  const ignore = [
    "webSlug",
    "webFlux",
    "webControlProtocolIntegrated",
    "webControlProtocolRemote",
  ];
  if (ignore.includes(name)) return;

  if (name === "webPower") {
    return {
      name,
      label: "System Power",
      values: values.map((value) => parseInt(value)).sort(),
      unitFront: "",
      unitBack: "W",
    };
  }

  // TODO sort Control Protocol by order from Airtable?
  if (name === "webControlProtocol") {
    // const sorting = [
    //   "Fixed Output",
    //   "Phase DIM",
    //   "DALI",
    //   "Push DIM",
    //   "Casambi Bluetooth",
    //   "0-10V",
    // ];
    // const sorted = values.sort(
    //   (a, b) => sorting.indexOf(a) - sorting.indexOf(b)
    // );

    const sorted = sortControlProtocols(values);

    return {
      name,
      label: "Control Protocol",
      values: sorted,
      unitFront: "",
      unitBack: "",
    };
  }

  if (name === "webDistribution") {
    // Sort by numerical value of distribution
    // TODO make a helper function for sorting distribution list
    const sorted = values
      .map((value) => {
        const numerical = getDistributionNumerical(value);
        if (numerical) return { value, numerical };
        return false;
      })
      .sort((a, b) =>
        a.numerical > b.numerical
          ? 1
          : a.numerical === b.numerical
          ? a.value > b.value
            ? 1
            : -1
          : -1
      );
    return {
      name,
      label: "Distribution",
      values: sorted.map((a) => a.value),
      unitFront: "",
      unitBack: "",
    };
  }

  if (name === "webFinish") {
    return {
      name,
      label: "Finish",
      values: values.sort(),
      unitFront: "",
      unitBack: "",
    };
  }

  if (name === "webColourTemperature") {
    return {
      name,
      label: "Colour Temperature",
      // values: values.map((value) => parseInt(value)).sort(),
      values: values.map((value) => value).sort(),
      unitFront: "",
      unitBack: "K",
    };
  }

  if (name === "webCRI") {
    return {
      name,
      label: "CRI",
      values: values.map((value) => parseInt(value)).sort(),
      unitFront: "Ra",
      unitBack: "",
    };
  }

  if (name === "wMCN") {
    return {
      name,
      label: "Mounting",
      values: values.map((value) => value).sort(),
      unitFront: "",
      unitBack: "",
    };
  }

  // if (name === "webControlProtocol") {
  //   return {
  //     name,
  //     label: "",
  //     values: values.map((value) => parseInt(value)).sort(),
  //     unitFront: "",
  //     unitBack: "",
  //   };
  // }
}
