import formatConfiguratorAttribute from "./formatConfiguratorAttribute";

export default function getconfiguratorAttributes(T1s) {
  if (!T1s || T1s.length < 1) return false;

  // clg({ T1s });

  // Get list of attributes
  const attributes = Object.getOwnPropertyNames(T1s[0].data);

  // clg(attributes);

  const configuratorAttributes = [];
  attributes.forEach((attribute) => {
    // Get all unique values of the attribute
    const values = [
      ...new Set(T1s.map((T1) => T1.data[attribute]).filter(Boolean)),
    ].sort();
    // clg({ attribute, values });

    // Only return attribute if more than one value present
    if (values.length > 1) {
      const formattedAttribute = formatConfiguratorAttribute(attribute, values);
      if (formattedAttribute) {
        configuratorAttributes.push(formattedAttribute);
      }
    }
  });

  // clg({ configuratorAttributes });

  return configuratorAttributes;
}
