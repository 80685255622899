import React from "react";
import { sortControlProtocols } from "../utils/helpers";

export default function ControlProtocolList({ protocols }) {
  // const order = [
  //   "Fixed Output",
  //   "Phase DIM",
  //   "DALI",
  //   "Push DIM",
  //   "Casambi",
  //   "0-10V",
  // ];
  // const sorted = [];
  // order.forEach((protocol) => {
  //   if (protocols.includes(protocol)) {
  //     sorted.push(protocol);
  //   }
  // });
  const sorted = sortControlProtocols(protocols);
  return (
    <>
      {sorted.map((CP) => (
        <li key={`CP-${CP}`}>{CP}</li>
      ))}
    </>
  );
}
