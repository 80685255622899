import React from "react";

export default function AttributeList({ prefix, unitFront, unitBack, values }) {
  // TODO Ugly hack to cater for RGBW Colour Temperature value

  return (
    <>
      {values.sort().map((value) => (
        <li key={`${prefix}-${value}`}>
          {unitFront}
          {value}
          {["RGB", "RGBW", "RGBTW"].includes(value) ? "" : unitBack}
        </li>
      ))}
    </>
  );
}
