import React from "react";

export default function DownloadList({
  downloadsList,
  allProductPhotometry,
  allInstallationManuals,
  allBrochures,
}) {
  // productDownloads.forEach((download) => {
  //   const fileData = allProductPhotometry.find(
  //     (x) => `${x.name}${x.ext}` === download.filename
  //   );
  //   if (fileData) download.fileData = fileData;
  // });

  // clg(downloadsList);

  if (downloadsList.photometry) {
    const photometryData = allProductPhotometry.find(
      (x) => `${x.name}${x.ext}` === downloadsList.photometry.filename
    );
    if (photometryData) downloadsList.photometry.data = photometryData;
  }

  if (downloadsList.installationManual) {
    const installationManualData = allInstallationManuals.find(
      (x) => `${x.name}${x.ext}` === downloadsList.installationManual.filename
    );
    if (installationManualData)
      downloadsList.installationManual.data = installationManualData;
  }

  if (downloadsList.brochure) {
    // clg("hello");
    const brochureData = allBrochures.find(
      (x) => `${x.name}${x.ext}` === downloadsList.brochure.filename
    );
    if (brochureData) downloadsList.brochure.data = brochureData;
  }

  return (
    <>
      {downloadsList.brochure && downloadsList.brochure.data && (
        <li>
          <a
            href={downloadsList.brochure.data.publicURL}
            className="product-download-link"
            download
          >
            {downloadsList.brochure.label}
            {downloadsList.brochure.aside && (
              <aside>{downloadsList.brochure.aside}</aside>
            )}
          </a>
        </li>
      )}
      {downloadsList.photometry && downloadsList.photometry.data && (
        <li>
          <a
            href={downloadsList.photometry.data.publicURL}
            className="product-download-link"
            download
          >
            {downloadsList.photometry.label}
            {downloadsList.photometry.aside && (
              <aside>{downloadsList.photometry.aside}</aside>
            )}
          </a>
        </li>
      )}
      {downloadsList.installationManual &&
        downloadsList.installationManual.data && (
          <li>
            <a
              href={downloadsList.installationManual.data.publicURL}
              className="product-download-link"
              download
            >
              {downloadsList.installationManual.label}
              {downloadsList.installationManual.aside && (
                <aside>{downloadsList.installationManual.aside}</aside>
              )}
            </a>
          </li>
        )}
      {/* {productDownloads.map((download, i) => (
        <li key={`product-download-${i}`}>
          <a
            href={download.fileData.publicURL}
            className="product-download-link"
            download
          >
            {download.label}
            {download.aside && <aside>{download.aside}</aside>}
          </a>
        </li>
      ))} */}
    </>
  );
}
