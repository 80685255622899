import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import getConfiguratorAttributes from "../utils/getConfiguratorAttributes";
import DistributionList from "./DistributionList";

export default function Configurator({ T1s, productPath }) {
  /* State */
  const [selectedAttributes, setSelectedAttributes] = useState({});
  const [shortlist, setShortlist] = useState([]);
  const [availableAttributes, setAvailableAttributes] = useState({});

  const toggleValueSelection = (target) => {
    const { attribute, value } = target;

    // 1. Make a copy of state
    const selection = { ...selectedAttributes };
    // 2. is the attribute/value in state?
    if (selection[attribute] && selection[attribute] === value) {
      // If found, remove it
      selection[attribute] = null;
    } else {
      // Else add it
      selection[attribute] = value;
    }
    // Update state
    setSelectedAttributes(selection);
  };

  const handleAttributeButtonClick = (e) => {
    e.preventDefault();
    toggleValueSelection({
      attribute: e.target.dataset.attribute,
      value: e.target.value,
    });
  };

  const Button = ({ attribute, value, label }) => (
    <button
      className={`button-capsule-link button-select small ${
        selectedAttributes[attribute] &&
        selectedAttributes[attribute] === value.toString() &&
        "selected"
      }`}
      type="button"
      value={value}
      data-attribute={attribute}
      onClick={handleAttributeButtonClick}
      disabled={
        availableAttributes[attribute] &&
        value &&
        availableAttributes[attribute] !== value.toString() &&
        true
      }
    >
      {label}
    </button>
  );

  const Result = () => {
    const T1 = {};
    if (shortlist.length === 1) {
      Object.assign(T1, shortlist[0].data);
    }
    if (shortlist.length !== 1) {
      return <></>;
    }
    // clg(T1.wMCN);
    return (
      <section
        className={`configurator-result-container ${
          shortlist.length === 1 && "show"
        }`}
      >
        {shortlist.length === 1 && (
          <>
            <dl className="grid-fixed-2 grid-fixed-mm-1 grid-fixed-ml-2 grid-align-center">
              <dt className="column">
                {/* <a
                  href={`${productPath}/${T1.webT4Slug}/${T1.webSlug}`}
                  target="_blank"
                  className="fs-large fs-ml-xl fs-mxxxl-xxl"
                  rel="noreferrer"
                >
                  {T1.webModelCode && T1.webModelCode}
                </a> */}
                <Link
                  to={`${productPath}/${T1.webT4Slug}/${T1.webSlug}`}
                  className="fs-large fs-ml-xl fs-mxxxl-xxl"
                >
                  {T1.webModelCode && T1.webModelCode}
                </Link>
              </dt>
              <dd className="column text-align-right hide-ml-only hide-ms">
                <Link
                  to={`${productPath}/${T1.webT4Slug}/${T1.webSlug}`}
                  className="capsule-link small"
                >
                  View Model Page
                </Link>
              </dd>
            </dl>
            <hr className="my-medium my-ml-small" />

            {T1.webPower ||
            T1.webFlux ||
            T1.webColourTemperature ||
            T1.webCRI ? (
              <>
                <dl className="flex-2-grid">
                  {T1.webPower && (
                    <>
                      <dt className="column">System Power</dt>
                      <dd className="column">
                        <ul>{T1.webPower}W</ul>
                      </dd>
                    </>
                  )}
                  {T1.webFlux && (
                    <>
                      <dt className="column">Luminous Flux</dt>
                      <dd className="column">
                        <ul>{T1.webFlux}lm</ul>
                      </dd>
                    </>
                  )}
                  {T1.webColourTemperature && (
                    <>
                      <dt className="column">Colour Temperature</dt>
                      <dd className="column">
                        <ul>{T1.webColourTemperature}K</ul>
                      </dd>
                    </>
                  )}
                  {T1.webCRI && (
                    <>
                      <dt className="column">CRI</dt>
                      <dd className="column">Ra{T1.webCRI}</dd>
                    </>
                  )}
                  {T1.webDistribution && (
                    <>
                      <dt className="column">Distribution</dt>
                      <dd className="column">
                        <ul>
                          {/* <AttributeList
                      values={T2.webDistribution}
                      prefix="Distribution"
                    /> */}
                          <DistributionList
                            distributions={[T1.webDistribution]}
                          />
                        </ul>
                      </dd>
                    </>
                  )}
                  {T1.webIPRating && (
                    <>
                      <dt className="column">IP Rating</dt>
                      <dd className="column">IP{T1.webIPRating}</dd>
                    </>
                  )}
                </dl>
                {/* <hr className="my-medium my-ml-small" /> */}
              </>
            ) : null}

            {/* {T1.webDistribution ? (
              <>
                <dl className="flex-2-grid">
                  {T1.webDistribution && (
                    <>
                      <dt className="column">Distribution</dt>
                      <dd className="column">
                        <ul>
                          <DistributionList
                            distributions={[T1.webDistribution]}
                          />
                        </ul>
                      </dd>
                    </>
                  )}
                </dl>
              </>
            ) : null} */}

            {/* {T1.webIPRating && (
              <>
                <hr className="my-medium my-ml-small" />
                <dl className="flex-2-grid">
                  <dt className="column">IP Rating</dt>
                  <dd className="column">IP{T1.webIPRating}</dd>
                </dl>
              </>
            )} */}

            {T1.webFinish || T1.wMCN ? (
              <>
                <hr className="my-medium my-ml-small" />
                <dl className="flex-2-grid">
                  <dt className="column">Finish</dt>
                  <dd className="column">{T1.webFinish}</dd>
                  <dt className="column">Mounting</dt>
                  <dd className="column">{T1.wMCN}</dd>
                </dl>
              </>
            ) : null}

            {T1.webControlProtocolRemote || T1.webControlProtocolIntegrated ? (
              <>
                <hr className="my-medium my-ml-small" />
                <dl className="flex-2-grid">
                  <dt className="column">Control</dt>
                  <dd className="column">
                    <dl>
                      <dt className="fs-tiny fw-medium">
                        {T1.webControlProtocolRemote ? "Remote" : "Integrated"}
                      </dt>
                      <dd>
                        {T1.webControlProtocolRemote
                          ? T1.webControlProtocolRemote
                          : T1.webControlProtocolIntegrated}
                      </dd>
                    </dl>
                    <aside className="fs-tiny c-fade">Driver Included</aside>
                  </dd>
                </dl>
              </>
            ) : null}
            <Link
              to={`${productPath}/${T1.webT4Slug}/${T1.webSlug}`}
              className="cover-link fs-large fs-ml-xl fs-mxxxl-xxl"
            >
              <div className="content">
                {/* <Link
                  to={`${productPath}/${T1.webT4Slug}/${T1.webSlug}`}
                  className="capsule-link small"
                >
                  View {T1.webModelCode && T1.webModelCode}
                </Link> */}
                View {T1.webModelCode && T1.webModelCode}
              </div>
            </Link>
          </>
        )}
      </section>
    );
  };

  /* selectedAttributes */
  useEffect(() => {
    const shortlistT1s = () => {
      if (!T1s || T1s.length < 1) return false;
      const newShortlist = [];
      T1s.forEach((T1) => {
        let selected = true;
        for (const [attribute, value] of Object.entries(selectedAttributes)) {
          if (value !== null) {
            if (T1.data[attribute]) {
              // clg(T1.data[attribute].toString());
              if (T1.data[attribute].toString() !== value.toString()) {
                selected = false;
              }
            } else {
              selected = false;
            }
          }
        }
        if (selected) newShortlist.push(T1);
      });
      setShortlist(newShortlist);
      // clg({ newShortlist });
    };
    shortlistT1s();
    // clg({ selectedAttributes });
  }, [T1s, selectedAttributes]);

  useEffect(() => {
    // TODO Tidy configurator buttons isn't properly implimented
    const tidyButtons = () => {
      const shortlistAttributes = getConfiguratorAttributes(shortlist);
      setAvailableAttributes(shortlistAttributes);
    };
    tidyButtons();
    // clg({ shortlist });
  }, [shortlist]);

  if (!T1s || T1s.length < 1) return <></>;
  const attributes = getConfiguratorAttributes(T1s);
  // clg({ attributes });
  if (!attributes || attributes.length < 1) return <></>;

  // TODO Includes ugly hacks for RGBW and range Colour Temperatures

  return (
    <>
      {/* <hr className="mt-xxl mb-small " /> */}
      {/* <ResultHeader /> */}

      <div className="grid-fixed-1 grid-fixed-mm-2 grid-fixed-mxl-2">
        <section className="configurator-panel-container">
          <div className="configurator-attribute-row">
            <h4 className="fs-large fs-ml-xl fs-mxxxl-xxl c-em">Configure</h4>
            <hr />
          </div>
          {attributes.map((attribute, i) => (
            <div
              key={`configurator-attribute-row-${i}`}
              className="configurator-attribute-row"
            >
              <h5 className="pb-xs">{attribute.label}</h5>
              <ul className="button-capsule-link-row">
                {/* {attribute.values
                .map((e) => `${attribute.unitFront}${e}${attribute.unitBack}`)
                .join(", ")} */}
                {attribute.values.map((value, x) => (
                  <li key={`button-${x}`}>
                    <Button
                      value={value}
                      attribute={attribute.name}
                      label={`${attribute.unitFront}${value}${
                        ["RGB", "RGBW", "RGBTW"].includes(value)
                          ? ""
                          : attribute.unitBack
                      }`}
                    />
                  </li>
                ))}
              </ul>
              {i + 1 < attributes.length && <hr />}
            </div>
          ))}
        </section>
        <Result />
      </div>
    </>
  );
}
