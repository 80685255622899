import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import ProductImage from "./ProductImage";

export default function ProjectImageGallery({ projectImages, T2Fullname }) {
  const projectImageQuery = useStaticQuery(graphql`
    query projectImageQuery {
      allImageSharp {
        nodes {
          gatsbyImageData(
            formats: JPG
            jpgOptions: { quality: 75 }
            sizes: "(min-width: 2000px) calc(25vw - 69px), (min-width: 1400px) calc(33.28vw - 71px), (min-width: 1100px) calc(40vw - 74px), (min-width: 580px) 500px, 89.23vw"
            placeholder: BLURRED
          )
          parent {
            ... on File {
              relativePath
            }
          }
        }
      }
    }
  `);

  const allProjectImages = projectImageQuery.allImageSharp.nodes;

  const projectImageData = projectImages
    .map((projectImage) => {
      const match = allProjectImages.find(
        (apiImage) =>
          apiImage.parent.relativePath === projectImage.data.File_Name
      );
      return (
        match && {
          gatsbyImageData: match.gatsbyImageData,
          name: match.parent.relativePath,
        }
      );
    })
    .filter(Boolean);

  // console.log(allProjectImages);
  // clg(projectImages);
  // clg(projectImageData);

  return (
    <div className="project-image-gallery">
      {projectImageData.map((data) => (
        <div key={data.name} className="project-image">
          <img src={data.gatsbyImageData.images.fallback.src} alt="file" />
        </div>
      ))}
    </div>
  );
}
